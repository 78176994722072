<!-- 社区矫正人员新增/编辑弹窗-->
<template>
  <div>
    <el-dialog
      :z-index="10"
      :title="title"
      :visible.sync="visible"
      width="60%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <!-- 左边竖线样式写法 -->
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        "
      >
        <div style="display: flex; align-items: center; margin-bottom: 2%">
          <div style="border-left: 4px solid #556bff">&nbsp;</div>
          <div class="search-title">基本信息</div>
        </div>
        <a-button v-if="isEdit" type="primary" @click="choosePeople">选择居民</a-button>
      </div>

      <!-- 基本信息 -->
      <div
        v-if="noChoice"
        style="
          background-color: #fff8de;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;
        "
      >
        <div style="margin-top: 6px; margin-bottom: 6px">
          &nbsp;
          <a-icon type="info-circle" style="color: #e6a23c" />请选择人员
        </div>
      </div>
      <ax-form v-else ref="form" :formBuilder="formBuilder1">
        <div slot="file">
          <imagePreview ref="img1" :businessId="businessId"></imagePreview>
        </div>
      </ax-form>
      <div style="display: flex; align-items: center; margin-bottom: 2%">
        <div style="border-left: 4px solid #556bff">&nbsp;</div>
        <div class="search-title">重点信息</div>
      </div>
      <!-- 重点信息 -->
      <ax-form ref="form1" :formBuilder="formBuilder2"></ax-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onSubmit"
          :loading="saveType"
          style="background-color: #556bff"
          >保 存</el-button
        >
        <el-button @click="visible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 选择居民弹窗 -->
    <choose-people
      ref="choosePeople"
      @receive="receivePeopleId" :selectedPeopleList="selectedPeopleList"
    ></choose-people>
  </div>
</template>
<script>
const formList1 = [
  {
    label: "姓名",
    type: "",
    model: "residentName",
    options: { allowClear: true, placeholder: "请输入" },
    col: { span: 12 },
    rules: [{ required: false, message: "请输入姓名" }],
  },
  {
    label: "个人照片",
    type: "",
    model: "file",
    options: {},
    col: { span: 12 },
    rules: [{ required: false, message: "" }],
  },
  {
    label: "身份证号",
    type: "",
    model: "identityCard",
    options: { allowClear: true, maxLength: 18, placeholder: "请输入" },
    col: { span: 12 },
    rules: [
      {
        required: false,
        message: "请输入",
        pattern:
          /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{4}$/ |
          /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$/,
      },
    ],
  },
  {
    label: "性别",
    type: "select",
    model: "gender",
    options: { disabled: true, placeholder: "请先填写身份证号" },
    col: { span: 12 },
    rules: [{ required: false, message: "请先填写身份证号" }],
  },
  {
    label: "年龄",
    type: "",
    model: "age",
    options: {
      disabled: true,
      placeholder: "请先填写身份证号",
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "出生日期",
    type: "datePicker",
    model: "birthDate",
    options: {
      disabled: true,
      placeholder: "请先填写身份证号",
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "联系电话",
    type: "",
    model: "phone",
    options: { placeholder: "请输入" },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "现住区域",
    type: "",
    model: "currentArea",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "详细地址",
    type: "",
    model: "detailedAddress",
    options: { placeholder: "请选择详细地址" },
    col: { span: 12 },
    rules: [{ required: false }],
  }
];
const formList2 = [
  {
    label: "具体罪名",
    type: "",
    model: "specificCharge",
    options: { maxLength: 20, placeholder: "请输入" },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "矫正类型",
    type: "select",
    model: "rectificationType",
    options: {},

    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "矫正开始日期",
    type: "datePicker",
    model: "rectificationDateStart",
    options: { placeholder: "请选择" },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "矫正结束日期",
    type: "datePicker",
    model: "rectificationDateEnd",
    options: { placeholder: "请选择" },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "是否建立矫正小组",
    type: "radio",
    model: "rectificationGroupFlag",
    options: {
      options: [
        { label: "是", value: "0" },
        { label: "否", value: "1" },
      ],
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "是否有托管",
    type: "radio",
    model: "tutelageFlag",
    options: {
      options: [
        { label: "是", value: "0" },
        { label: "否", value: "1" },
      ],
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
];
import api from "./api";
import imageUpload from "./ax-image-modal copy/src/image-upload.vue";
import imagePreview from "./image-preview/imagePreview";
import ChoosePeople from "./choosePeople.vue";
export default {
  components: {
    imagePreview,
    ChoosePeople,
    choosePeopleId: "",
  },
  data() {
    return {
      options: [],
      visible: false,
      noChoice: true,
      isEdit: true,
      businessId: "",
      id: "",
      title: "",
      disabled: false,
      fileIds: [], //图片数组
      saveType:false,
      formBuilder1: this.$common.initGridFormData(
        formList1,
        { layout: "horizontal", labelWidth: 150, disabled: true },
        { col: { span: 8 } }
      ),
      formBuilder2: this.$common.initGridFormData(
        formList2,
        { layout: "horizontal", labelWidth: 150 },
        { col: { span: 8 } }
      ),
      baseInfoValue: {}, //基本信息
      KeyInformation: {}, //重点信息
      selectedPeopleList:[]
    };
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    // 打开选择居民弹窗
    choosePeople() {
      this.$refs.choosePeople.openModal();
    },
    // 接收子组件传过来的peopleId
    receivePeopleId(item) {
      if (item[0]) {
        this.noChoice = false;
        this.getPersonInfo(item[0]);
        this.businessId = item[0];
        this.$nextTick(() => {
          this.$refs.img1.getPicList(item[0]);
        });
      }
    },
    // 级联选择器改变的回调
    onChange(value) {
      console.log(value);
    },
    // 打开弹窗
    openModal(record = {}) {
      this.visible = true;
      this.noChoice = true;
      this.isEdit= true,
      this.saveType = false
      this.title = record.title;
      this.selectedPeopleList = record.selectedPeopleList
      this.$nextTick(() => {
        this.getDictionsary();
        this.$refs.form1.resetFields();
      });
      if (record.id) {
        this.noChoice = false;
        this.isEdit = false;
        this.id = record.id;
        this.businessId = record.residentId;
        this.getRectificationInfo(record.id);
        this.getPersonInfo(record.residentId);
        this.$nextTick(() => {
          this.$refs.img1.getPicList(record.residentId);
        });
      } else {
        this.id = "";
      }
    },
    // 关闭弹窗
    closeModal() {
      this.visible = false;
      this.$refs.form1.resetFields();
      this.$emit("refsh");
    },
    // 获取字典
    async getDictionsary() {
      // 社区矫正人员原罪名丝
      await api
        .dictData({ dicKind: "emancipist_original_charge" })
        .then((res) => {
          const options = res.data.map((res) => {
            return { label: res.dicDesc, value: res.dicCode };
          });
          this.$refs.form1.setFormItemProp("originalCharge", {
            options: { options, allowClear: true, placeholder: "请选择" },
          });
        });
      // 社区矫正人员危险性评估
      await api
        .dictData({ dicKind: "emancipist_risk_assessment" })
        .then((res) => {
          const options = res.data.map((res) => {
            return { label: res.dicDesc, value: res.dicCode };
          });
          this.$refs.form1.setFormItemProp("riskAssessment", {
            options: { options, allowClear: true, placeholder: "请选择" },
          });
        });
      // 社区矫正人员安置情况
      await api.dictData({ dicKind: "emancipist_settle_type" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form1.setFormItemProp("settleType", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 社区矫正人员衔接方式
      await api.dictData({ dicKind: "emancipist_link_method" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form1.setFormItemProp("linkMethod", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 矫正类型
      await api.dictData({ dicKind: "rectification_type" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form1.setFormItemProp("rectificationType", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
    },
    // 身份证号计算性别/年龄/出生日期
    calculateIDCardInfo(idCard) {
      let info = {
        gender: "",
        age: "",
        birthDate: "",
      };

      if (!idCard || (idCard.length !== 15 && idCard.length !== 18)) {
        return info;
      }

      let year =
        idCard.length === 15 ? "19" + idCard.substr(6, 2) : idCard.substr(6, 4);
      let month = idCard.substr(idCard.length === 15 ? 8 : 10, 2);
      let day = Number(idCard.substr(idCard.length === 15 ? 10 : 12, 2)) + 1;

      let genderCode =
        idCard.substr(idCard.length - 2, 1) % 2 === 0 ? "女" : "男";
      let birthday = new Date(year, parseInt(month) - 1, day); // 将月份减一
      let age = new Date().getFullYear() - birthday.getFullYear();
      info.gender = genderCode;
      info.age = age;
      info.birthDate = birthday.toISOString().slice(0, 10);
      this.$refs.form.setFieldsValue({ birthDate: info.birthDate });
      this.$refs.form.setFieldsValue({ gender: info.gender });
      this.$refs.form.setFieldsValue({ age: info.age });
      return info;
    },
    // 提交
    async onSubmit() {
     
      if (this.noChoice) {
        this.$message.warning("请先选择居民");
        return;
      }
      this.$refs.form.form.validateFields(async (err, value) => {
        if (err) return;
        console.log(value);
        this.baseInfoValue.residentId = this.businessId;
        console.log(this.baseInfoValue);
        if (this.$refs.form1) {
          this.$refs.form1.form.validateFields(async (err, value1) => {
            if (err) return;
            console.log(value1);
            this.KeyInformation = value1;
            this.KeyInformation.rectificationType = value1.rectificationType||'';
            const totalValue = {
              ...this.baseInfoValue,
              ...this.KeyInformation,
            };
            console.log(totalValue, "输入数据");
            this.saveType = true;
            if (this.id) {
              totalValue.id = this.id;
              const res = await api.updateRectification(totalValue);
              console.log(res);
              if (res.status === 200) {
                this.$message.success("修改成功");
                this.saveType = false;
                this.closeModal();
              }
            } else {
              const res = await api.addRectification(totalValue);
              console.log(res);
              if (res.status === 200) {
                this.$message.success("新增成功");
                this.saveType = false;
                this.closeModal();
                this.$refs.form.resetFields();
                // this.$refs.form1.resetFields()
                // this.$refs.form2.resetFields()
                // this.$refs.form3.resetFields()
              }
            }
          });
        }
      });
    },
    // 根据ID查询图片
    async picVisitor(id) {
      const res = await api.picVisitor(id);
      console.log(res, "260");
    },
    // 根据id获取详情
    async getPersonInfo(id) {
      const res = await api.getResidentArchivesById(id);
      this.$refs.form.setFieldsValue(res.data);
        this.$refs.form.setFieldsValue({
        detailedAddress: res.data.cellName
          ? `${res.data.cellName}/${res.data.buildingNum}/${res.data.unitNum}/${res.data.floorNum}/${res.data.accountNum}`
          : "",
      });
      this.calculateIDCardInfo(res.data.identityCard);
    },
    // 根据id获取社区矫正人员详情
    async getRectificationInfo(id) {
      const res = await api.getRectificationById(id);
      this.$refs.form1.setFieldsValue(res.data);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}

.dialog-footer {
  display: flex;
  justify-content: end;
}

.search-title {
  // font-size: 0.072rem;
  font-weight: 800;
}
</style>